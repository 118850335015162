// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// if you're using webpacker for stylesheets, uncomment
// import 'stylesheets/application.scss'

import Rails from '@rails/ujs'
import "@hotwired/turbo-rails"
import * as ActiveStorage from '@rails/activestorage'

// if you're using ActionText, uncomment
import 'trix'
import '@rails/actiontext'

// if you're using ActionCable channel classes, uncomment
import 'channels'

// if you want to use webpacker for images, uncomment
// const images = require.context('../images', true)
// const imagePath = name => images(name, true)

require("@fortawesome/fontawesome-free")
require("typeface-quando")
require("@fontsource/roboto-slab")

// Added JQuery and Popper for Bootstrap
require('jquery')
require('popper.js')
require("bootstrap")
import 'stylesheets/application'
window.jQuery = $;
window.$ = $;

import "controllers/index.js"

Rails.start()
ActiveStorage.start()

// $(document).ready(function(){
//   $('.toast').toast("show");
// });

document.addEventListener("turbo:load", function() {
  $('[data-toggle="tooltip"]').tooltip()
  // find and attach resize stuff to text areas
  const tx = document.getElementsByTagName('textarea');
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
    tx[i].addEventListener("input", OnInput, false);
  }
})

// Hacky to copy this but it works and I was having trouble getting it to work otherwise.
// This allows for text areas to be resized when they are added via simulus reflex.
document.addEventListener("DOMNodeInserted", function() {
  // find and attach resize stuff to text areas
  const tx = document.getElementsByTagName('textarea');
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
    tx[i].addEventListener("input", OnInput, false);
  }
})

// Function to auto-size text areas based on content
function OnInput() {
  this.style.height = 'auto';
  this.style.height = (this.scrollHeight) + 'px';
}
